import "jquery-ui";
import $ from "jquery";

import React, { useRef, useMemo, useCallback, useState } from "react";
import debounce from "lodash.debounce";

import usePartyRequest, { useSaveRequest } from "../hooks/usePartyRequest";
import config from "../config";

import { Error, DatePicker, Notification, CompleteDialog } from "../components";

import Loading, { Spinner } from "../components/loading";

import documentPane, { styles } from "../documentpane";

import { ReactComponent as DoneSvg } from "../images/done.svg";
import { ReactComponent as SetupSvg } from "../images/setup.svg";
import { ReactComponent as HighFiveSvg } from "../images/highfive.svg";
import { ReactComponent as ChecklistSvg } from "../images/checklist.svg";

import * as pdfjsLib from "pdfjs-dist/legacy/build/pdf";
import pdfjsWorker from "pdfjs-dist/legacy/build/pdf.worker.entry";

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const SCHEMA_REGEX = /\/img\/forms\//g;

const privacyPolicies = {
  ACT: "https://www.reiact.com.au/privacy-policy",
  VIC: "https://formslive.com.au/static/privacy-f29c11510034669a524a0ff1b8671b3f.pdf",
  QLD: "https://www.reiq.com/privacy-policy/",
  NSW: "https://nsw.reiformslive.com.au/pdf/privacy-policy.pdf",
  WA: "https://reiwa.com.au/privacy/",
  SA: "https://www.reisa.com.au/publicinfo/privacy-policy",
  FLSA: "https://sa.formslive.com.au/privacy.pdf",
};

function getPrivacyPolicy(platform) {
  var link = privacyPolicies[platform];
  if (!link) return null;

  return (
    <div className="flex items-center justify-center mt-4">
      <div className="rounded-md bg-yellow-50 p-4 mt-8 inline-flex">
        <div className="text-sm text-yellow-700 text-center">
          <p>
            By continuing, I understand and agree to the{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "underline" }}
              href={link}
            >
              Privacy Policy
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
}

const mapSchemaUrls = (schema) =>
  schema.replace(SCHEMA_REGEX, `${config.ui}/img/forms/`);

const PartyRequest = ({ platform, formId, requestId }) => {
  const [notification, setNotification] = useState(null);
  const [showCompleteDialog, setCompleteOpen] = useState(false);
  const [loading, request, error, refresh, lastUpdated] = usePartyRequest(
    formId,
    requestId
  );
  const [handleSubmit, processing] = useSaveRequest(
    formId,
    requestId,
    setNotification
  );
  const [showIntro, setIntroOpen] = useState(true);

  const closeNotification = useCallback(() => {
    setNotification(null);
  }, []);

  const completeClickHandler = () => setCompleteOpen(true);

  const completeClose = () => setCompleteOpen(false);

  const handleSave = useCallback(
    async (complete, opts = {}) => {
      var viewer = $("#viewer");

      var values = viewer.blur().documentpane("dirty");

      var success = await handleSubmit(values, complete);

      if (success) {
        setNotification({
          success: true,
          title: opts.title || "Details Saved",
          message: opts.message || "Successfully saved the form's data",
        });

        if (complete) {
          setCompleteOpen(false);
          refresh();
        }
      }
    },
    [handleSubmit, refresh]
  );

  const onSave = useCallback(
    (opts = {}) => {
      handleSave(false, opts);
    },
    [handleSave]
  );

  const versionSchema = useMemo(() => {
    try {
      return (
        request &&
        JSON.parse(mapSchemaUrls(request.schema.template_version.schema))
      );
    } catch {
      return null;
    }
  }, [request]);

  const annexures = useMemo(() => {
    try {
      return request.schema.annexures.map((a) => ({
        ...a,
        schema: JSON.parse(mapSchemaUrls(a.schema)),
      }));
    } catch {
      return [];
    }
  }, [request]);

  const values = useMemo(() => {
    if (!request) {
      return {};
    }

    return request.form.values.reduce((m, v) => {
      return { ...m, [v.name]: v.value };
    }, {});
  }, [request]);

  const readonly = useMemo(() => {
    try {
      return request.schema.fields.reduce((m, field) => {
        if (~request.party_request.fields.indexOf(field.name)) {
          return m;
        }

        return [...m, field.name];
      }, []);
    } catch {
      return [];
    }
  }, [request]);

  if (loading) {
    return <Loading />;
  }

  if (error || !request) {
    return <Error message={error} />;
  }

  if (request.party_request.completed) {
    return (
      <Complete
        user={request.user}
        agency={request.agency}
        request={request.party_request}
      />
    );
  }

  if (showIntro) {
    return (
      <Intro
        user={request.user}
        agency={request.agency}
        request={request.party_request}
        template={request.schema.template}
        platform={platform}
        onNext={() => setIntroOpen(false)}
      />
    );
  }

  return (
    <React.Fragment>
      <style>{styles(platform)}</style>
      <Notification onClose={closeNotification} {...(notification || {})} />
      <CompleteDialog
        onSubmit={() => handleSave(true)}
        onClose={completeClose}
        isOpen={!processing && showCompleteDialog}
      />
      <Form
        onSave={onSave}
        values={values}
        key={lastUpdated}
        readonly={readonly}
        platform={platform}
        form={request.form}
        formFiles={request.form_files}
        partyRequestId={request.party_request.id}
        annexures={annexures}
        processing={processing}
        schema={request.schema}
        agency={request.agency}
        versionSchema={versionSchema}
        title={request.schema.template.name}
        writeonly={request.party_request.fields}
        updated={request.party_request.updated}
        onCompleteClick={completeClickHandler}
      />
    </React.Fragment>
  );
};

const Form = (props) => {
  const activeDate = useRef(null); //TODO move date logic
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const onDateSubmit = useCallback((date) => {
    $("#viewer").documentpane(
      "setTimestamp",
      activeDate.current.data.name,
      date
    );

    setDatePickerOpen(false);
  }, []);

  const onDatePickerClose = useCallback(() => setDatePickerOpen(false), []);

  const setZoom = useCallback((scale) => {
    var viewer = $("#viewer");

    viewer.documentpane("fit", "custom");
    viewer.documentpane("scale", scale);
    viewer.documentpane("resize");
  }, []);

  const autoSize = useCallback(
    (scale) => {
      var viewerWidth = $("#viewer").innerWidth();
      var contentWidth = $(".doc-content").innerWidth();

      setZoom((viewerWidth * 0.9) / contentWidth);
    },
    [setZoom]
  );

  const zoomIn = useCallback(() => {
    setZoom($("#viewer").documentpane("scale") * 1.1);
  }, [setZoom]);

  const zoomOut = useCallback(() => {
    setZoom($("#viewer").documentpane("scale") * 0.9);
  }, [setZoom]);

  const onDateClick = useCallback((e, data) => {
    activeDate.current = {
      data: data,
      inputName: $(e.originalEvent.target).name,
    };

    setDatePickerOpen(true);
  }, []);

  const debounceSave = useMemo(() => {
    const opts = {
      title: "Auto Saved",
      message: "Form has been successfully auto saved",
    };

    return debounce(props.onSave.bind(null, opts), 4000);
  }, [props.onSave]);

  React.useLayoutEffect(() => {
    documentPane(props.platform)($);
  }, [props.platform]);

  React.useLayoutEffect(() => {
    const viewer = $("#viewer").documentpane({
      fit: "width",
      data: props.values,
      prescale: false,
      required: false,
      logo:
        props.agency.logo &&
        ["data:image/png;base64", props.agency.logo].join(", "),
      readonly: props.readonly,
      writeonly: props.writeonly,
      finalised: props.isComplete || props.form.finalised,
      locked: props.isComplete || props.form.finalised,
      schema: props.versionSchema,
      annexures: props.annexures,
      annexureTags: props.schema.annexureTags,
      signFields: [],
      variables: {
        document_id: "",
        accredited: "",
      },
    });

    viewer.on("documentpanedate", onDateClick);
    viewer.on("documentpaneupdated", debounceSave);

    return () => {
      viewer.off("documentpanedate", onDateClick);
      viewer.off("documentpaneupdated", debounceSave);
    };
  }, [
    props.form,
    onDateClick,
    debounceSave,
    props.values,
    props.schema,
    props.agency,
    props.writeonly,
    props.platform,
    props.readonly,
    props.annexures,
    props.isComplete,
    props.versionSchema,
  ]);

  React.useLayoutEffect(() => {
    autoSize();
  }, [autoSize]);

  React.useLayoutEffect(
    function renderAttachments() {
      var queue = {};
      var current = 0;

      const queueFiles = (files) => {
        queue = files;
        current = 0;
        load();
      };

      const renderPage = (pdf, pageNumber, fileId) => {
        pdf.getPage(pageNumber).then(function (page) {
          var desiredWidth = 2100;
          var _viewport = page.getViewport({ scale: 1 });
          var scale = desiredWidth / _viewport.width;
          var viewport = page.getViewport({ scale: scale });

          var canvas = $(`#file_${fileId}_${pageNumber}`)[0];
          var context = canvas.getContext("2d");
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          var renderContext = {
            canvasContext: context,
            viewport: viewport,
          };

          var renderTask = page.render(renderContext);

          renderTask.promise.then(function () {
            if (pageNumber + 1 <= pdf.numPages) {
              renderPage(pdf, pageNumber + 1, fileId);
            } else {
              current++;
              load();
            }
          });
        });
      };

      const load = () => {
        var file = queue[current];

        if (!file) return false;

        var url = `${config.api}/requests/${props.form.id}/${props.partyRequestId}/files/${file.id}`;

        var _loadingTask = pdfjsLib.getDocument(url);

        _loadingTask.promise.then(function (pdf) {
          renderPage(pdf, 1, file.id);
        });
      };

      var items = [];
      var formFiles = props.formFiles || [];

      var getFields = function (page) {
        return fields.filter(function (f) {
          return f.page === page;
        });
      };

      for (var j = 0; j < formFiles.length; j++) {
        var fileIndex = formFiles[j].id;
        var numPages = formFiles[j].pages;
        var fields = formFiles[j].fields || [];

        for (var i = 0; i < numPages; i++) {
          items.push({
            index: formFiles[j].index,
            pages: [
              {
                form: getFields(i + 1),
                content: [],
                background: [
                  {
                    type: "text",
                    left: 0,
                    top: 0,
                    width: 2100,
                    height: 2970,
                    maxWidth: 2100,
                    maxHeight: 2970,
                    html: `<canvas id="file_${fileIndex}_${i + 1}"></canvas>`,
                  },
                ],
              },
            ],
          });
        }
      }

      queueFiles(formFiles);
      $("#viewer").documentpane("updateAttachments", items);
      autoSize();
    },
    [props.form.id, props.formFiles, props.partyRequestId, autoSize]
  );

  return (
    <div
      style={{ height: "calc(100vh - 64px)" }}
      className="bg-gray-50 flex flex-col overflow-hidden"
    >
      <div
        style={{ zIndex: 10 }}
        className={
          "flex items-center justify-between py-2.5 px-6 shadow border-b border-gray-200 bg-white"
        }
      >
        <div className="flex flex-row">
          <button
            className="mr-1 inline-flex items-center px-1.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-gray-800 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            onClick={zoomOut}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM13 10H7"
              />
            </svg>
          </button>
          <button
            className="inline-flex items-center px-1.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-gray-800 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            onClick={zoomIn}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM10 7v3m0 0v3m0-3h3m-3 0H7"
              />
            </svg>
          </button>
        </div>
        <h1 className="font-semibold text-md text-gray-800 leading-5 text-center px-4 truncate text-ellipsis overflow-hidden">
          {props.title}
        </h1>
        <div className="flex flex-row">
          <button
            disabled={props.processing}
            className={
              "relative inline-flex items-center px-2.5 py-1.5 leading-5 border border-transparent text-sm font-medium rounded shadow-sm text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 " +
              (props.processing
                ? "pl-8 bg-gray-400 pointer-events-none"
                : "bg-gray-800")
            }
            onClick={props.onSave}
          >
            {props.processing && <Spinner />} Save
          </button>
          <button
            disabled={props.processing}
            className={
              "relative ml-2 inline-flex items-center px-2.5 py-1.5 leading-5 border border-transparent text-sm font-medium rounded shadow-sm text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 " +
              (props.processing
                ? "pl-8 bg-gray-400 pointer-events-none"
                : "bg-green-500")
            }
            onClick={props.onCompleteClick}
          >
            {props.processing && <Spinner />} Complete
          </button>
        </div>
      </div>
      <DatePicker
        onSubmit={onDateSubmit}
        isOpen={datePickerOpen}
        onClose={onDatePickerClose}
        defaultDate={
          activeDate.current &&
          activeDate.current.data &&
          activeDate.current.data.initialValue
        }
      />
      <div className="relative overflow-auto">
        <div id="viewer" className="relative pb-8 origin-top" />
      </div>
    </div>
  );
};

const Complete = ({ agency, user, request }) => {
  return (
    <div className="mt-10 mx-auto max-w-screen-xl px-8 mt-8 xl:mt-16">
      <div className="text-center">
        <h2 className="text-3xl mb-8 font-semibold leading-6 text-gray-900">
          Thanks {request.name}, you're all set!
        </h2>
      </div>
      <div>
        <HighFiveSvg
          className="h-64 w-64 sm:w-auto mx-auto my-12 xl:my-24 text-secondary"
          alt="Complete"
        />
      </div>
      <p className="text-xl text-center text-gray-500 my-8">
        If you have any questions, please reach out to us.
      </p>

      <div className="mx-auto max-w-2xl grid grid-cols-1 sm:grid-cols-2 gap-8 my-8 lg:my-16">
        <div className="flex flex-col justify-between items-baseline">
          <div>
            <p className="text-2xl font-semibold text-primary leading-12">
              {agency.name}
            </p>
            <p className="text-xl text-gray-500">{agency.phone}</p>
            <p className="text-lg text-gray-500">{agency.email}</p>
          </div>
          <a
            type="button"
            className="w-full sm:w-auto inline-flex justify-center text-primary-btn-text bg-primary-btn-background rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 px-12 py-3 text-md font-medium mt-2"
            href={"mailto:" + agency.email}
          >
            Email Agency
          </a>
        </div>

        <div className="flex flex-col justify-between items-baseline">
          <div>
            <p className="text-2xl font-semibold text-primary leading-12">
              {user.given_name} {user.surname}
            </p>
            <p className="text-xl text-gray-500">{user.mobile}</p>
            <p className="text-lg text-gray-500">{user.email}</p>
          </div>
          <a
            type="button"
            className="w-full sm:w-auto inline-flex justify-center text-primary-btn-text bg-primary-btn-background rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 px-12 py-3 text-md font-medium mt-2"
            href={"mailto:" + user.email}
          >
            Email Agent
          </a>
        </div>
      </div>
    </div>
  );
};

const Intro = ({ request, user, template, agency, platform, onNext }) => {
  var privacyPolicy = getPrivacyPolicy(platform);

  return (
    <div className="mt-10 mx-auto max-w-screen-xl px-8 mt-8 xl:mt-16">
      <div className="w-full mt:8 xl:mt-24 overflow-hidden text-left align-middle text-center">
        <h3 className="text-3xl mb-8 font-semibold leading-6 text-gray-900">
          Hello, {request.name}!
        </h3>
        <div className="mt-4">
          <p className="text-gray-800 text-lg leading-6">
            <span className="font-semibold">{user.given_name} </span>here from{" "}
            <span className="font-semibold">{agency.name}</span>.
          </p>
          <p className="text-gray-800 text-lg leading-8">
            We need your help completing the {template.name}.
          </p>

          <h3 className="text-xl font-semibold leading-12 text-gray-900 mt-8 lg:mt-16">
            We've made the process super simple for you!
          </h3>
          <p className="text-gray-500 text-lg leading-8">
            Here are a few pointers to help make it as painless as possible
          </p>
        </div>
      </div>

      <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-8 mt-4">
        <div className="text-center">
          <SetupSvg
            className="p-4 py-8 w-full h-64 text-secondary"
            alt="Form Fields"
          />
          <p className="px-6 mt-4">
            All fields that need to be filled out are highlighted for you
          </p>
        </div>
        <div className="text-center">
          <ChecklistSvg
            className="p-4 py-12 h-64 w-full text-secondary"
            alt="Save Progress"
          />
          <p className="px-6 mt-4">
            Save your progress by clicking the{" "}
            <span className="font-semibold">Save</span> button & return at any
            point
          </p>
        </div>
        <div className="text-center">
          <DoneSvg
            className="p-4 w-full h-64 text-secondary"
            alt="Complete Document"
          />
          <p className="px-6 mt-4">
            Submit the completed form by clicking the{" "}
            <span className="font-semibold">Complete</span> button
          </p>
        </div>
      </div>

      <div className="flex items-center justify-center mt-4">
        <div className="rounded-md bg-yellow-50 p-4 mt-8 inline-flex">
          <div className="text-sm text-yellow-700 text-center">
            <p>
              <span className="font-semibold">Note</span> You are not required
              to sign the form at this stage. Once completed, the agent will
              review the information and be in touch.
            </p>
          </div>
        </div>
      </div>

      {privacyPolicy}

      <div className="flex justify-center mt-8 pb-8">
        <button
          type="button"
          className="w-full md:w-auto inline-flex justify-center text-primary-btn-text bg-primary-btn-background border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500  px-6 py-3 text-md font-medium xl:px-16 xl:py-4 xl:text-xl"
          onClick={onNext}
        >
          Get Started
        </button>
      </div>
    </div>
  );
};

export default PartyRequest;
